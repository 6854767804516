import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { UserApiService } from '../../../user/services/user-api.service';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ExternalService } from '../../api/external.service';
import { SpacePipe } from '../../pipes/space.pipe';
import { FeaturedDataServiceService } from '../../services/featured-data-service.service';
import { RemoveHtmlTagPipe } from '../../../shared/pipes/remove-html-tag.pipe';
import { BuyerCurrencyI, CurrencyService } from '../../services/currency.service';
import { CustomCurrencyPipe } from "../../pipes/custom-currency.pipe";
@Component({
  selector: 'app-product-card',
  standalone: true,
  imports: [CommonModule, MatMenuModule, SpacePipe, RemoveHtmlTagPipe, CustomCurrencyPipe],
  templateUrl: './product-card.component.html',
  styleUrl: './product-card.component.scss',
})
export class ProductCardComponent {
  constructor(
    private userService: UserApiService,
    private atuhService: AuthService,
    private router: Router,
    public dialog: MatDialog,
    private externalService: ExternalService,
    private featuredDataService: FeaturedDataServiceService,
    private currencyService: CurrencyService,
    private authService: AuthService
  ) {}
  @Input() id = '';
  @Input() journey: { name: string; id: string } | undefined;
  @Input() tagline = '';
  @Input() title = '';
  @Input() time = 3;
  @Input() minTime: null | number = null;
  @Input() price = 500;
  @Input() minPrice: null | number = null;
  @Input() type = '';
  @Output() onMenuClick = new EventEmitter();
  @Input() isBookmarked?: boolean;
  @Input() isFeatured = false;
  @Input() sku: string = '';
  @Input() description: string = ''
  // currency = this.externalService.getFullCurrency();
  // isSuperAdmin = false;
  currency: BuyerCurrencyI = this.currencyService.getCurrency();
  ngOnInit() {
    if(this.type == "buyer" || this.type == "user"){
      this.currencyService.currency().subscribe(res=>{
        this.currency = res
      })
    }else{
      const externalCurr = this.externalService.getFullCurrency()
      this.currency = {code: externalCurr.code, exchangeRate: 1};
    }
    // this.authService.isSuperAdmin().subscribe(res=>{
    //   this.isSuperAdmin = res
    // })
    if (!this.isBookmarked) {
      this.userService.bookmarkedProducts.subscribe((res) => {
        this.isBookmarked = !!res.find((prod) => prod == this.id);
      });
    }
    if (this.type == 'admin') {
      this.featuredDataService.getFeaturedProducts().subscribe((res) => {
        if (res.find((prod) => prod.id == this.id)) {
          this.isFeatured = true;
        } else {
          this.isFeatured = false;
        }
      });
    }
  }
  
  goToDetails() {
    if (this.type == 'admin') {
      this.onMenuClick.emit('edit');
      return;
    }
    this.router.navigate([`/product/${this.id}`]);
  }
}
