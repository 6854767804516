import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface ToastI {
  title: string;
  message: string;
  show?: boolean;
  type?: 'success' | 'error';
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor() {}

  private toastSubject = new Subject<ToastI>();

  add({ message, title, type = 'success', show = true }: ToastI) {
    this.toastSubject.next({ message, title, type, show });
  }

  state() {
    return this.toastSubject.asObservable();
  }

  showSuccess() {
    this.toastSubject.next({
      message: 'You changes have bein successfully saved',
      title: 'Saved Successfully!',
      type: 'success',
      show: true,
    });
  }
  showDeletedSuccess() {
    this.toastSubject.next({
      message: 'You have successfully deleted the product',
      title: 'Deleted Successfully!',
      type: 'success',
      show: true,
    }); 
  }
  showFormErr() {
    this.toastSubject.next({
      title: 'Something went wrong!',
      message: 'Please make sure all required fields are filled out',
      type: 'error',
      show: true,
    });
  }
}
