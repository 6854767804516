import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { UserApiService } from '../../user/services/user-api.service';
import { CurrencyService } from './currency.service';
import { BehaviorSubject } from 'rxjs';

export interface ILoginResponse {
  accessToken: string;
  refreshToken: string;
  expiresDate: number;
  role: string;
  timezone: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly accessTokenKey = 'accessToken';
  private readonly refreshTokenKey = 'refreshToken';
  private readonly expiresDateKey = 'expiresDate';
  private readonly roleKey = 'role';
  private readonly timezoneKey = 'timezone';

  constructor(
    private router: Router,
    private http: HttpClient,
    private userApi: UserApiService
  ) {}

  public refreshToken() {
    let body = new URLSearchParams();
    body.set('refreshToken', this.getTokens().refreshToken);
    return this.http.post<ILoginResponse>(
      `${environment.API_URL}auth/refresh`,
      body,
      {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
      }
    );
  }

  public isAdmin(): boolean {
    const tokens = this.getTokens();
    if (!tokens.accessToken) {
      return false;
    }
    return Date.now() <= tokens.expiresDate && tokens.role == 'admin';
  }
  
  public isAgency(): boolean {
    const tokens = this.getTokens();
    if (!tokens.accessToken) {
      return false;
    }
    return Date.now() <= tokens.expiresDate && tokens.role == 'agency-owner';
  }

  public isProvider(): boolean {
    const tokens = this.getTokens();
    if (!tokens.accessToken) {
      return false;
    }
    return Date.now() <= tokens.expiresDate && tokens.role == 'provider';
  }

  public isBuyer(): boolean {
    const tokens = this.getTokens();
    if (!tokens.accessToken) {
      return false;
    }
    return Date.now() <= tokens.expiresDate && tokens.role == 'buyer';
  }
  private superAdmin = new BehaviorSubject<boolean>(false);
  public setSuperAdmin(isSuper: boolean) {
    console.log('setting Super admin', isSuper);
    
    this.superAdmin.next(isSuper);
  }
  public isSuperAdmin() {
    return this.superAdmin.asObservable();
  }

  public logOut() {
    this.userApi.emptyBookmakr();
    this.removeTokens();
    this.router.navigate(['/']);
  }

  public logOutAndGotoLogin() {
    this.userApi.emptyBookmakr();
    this.removeTokens();
    this.router.navigate(['/login']);
  }

  public removeTokens() {
    localStorage.removeItem(this.accessTokenKey);
    localStorage.removeItem(this.refreshTokenKey);
    localStorage.removeItem(this.expiresDateKey);
    localStorage.removeItem(this.roleKey);
  }

  public setTokens(tokens: ILoginResponse) {
    localStorage.setItem(this.accessTokenKey, tokens.accessToken);
    localStorage.setItem(this.refreshTokenKey, tokens.refreshToken);
    localStorage.setItem(this.expiresDateKey, tokens.expiresDate.toString());
    localStorage.setItem(this.roleKey, tokens.role);
  }
  public getTokens(): ILoginResponse {
    return {
      accessToken: localStorage.getItem(this.accessTokenKey) || '',
      refreshToken: localStorage.getItem(this.refreshTokenKey) || '',
      expiresDate: Number(localStorage.getItem(this.expiresDateKey)),
      role: localStorage.getItem(this.roleKey) || '',
      timezone: localStorage.getItem(this.timezoneKey) || '',
    };
  }

  public onError() {
    this.router.navigate(['/']);
  }

  public login(data: { email: string; password: string }) {
    let body = new URLSearchParams();
    body.set('email', data.email);
    body.set('password', data.password);
    return this.http.post<ILoginResponse>(`${environment.API_URL}auth`, body, {
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
  }

  public registerProvider(body: any) {
    return this.http.post<ILoginResponse>(
      `${environment.API_URL}external/register/provider`,
      body,
      {}
    );
  }

  public registerAgency(body: any) {
    return this.http.post<ILoginResponse>(
      `${environment.API_URL}external/register/agency-owner`,
      body,
      {}
    );
  }

  public registerBuyer(body: any) {
    return this.http.post<ILoginResponse>(
      `${environment.API_URL}external/register/buyer`,
      body,
      {}
    );
  }

  public forgotPassword(email: string) {
    let body = new URLSearchParams();
    body.set('email', email);

    return this.http.post(`${environment.API_URL}auth/forgot-password`, body, {
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
  }

  public sendConfirmEmail(email: string) {
    let body = new URLSearchParams();
    body.set('email', email);

    return this.http.post(`${environment.API_URL}auth/send-confirm-email`, body, {
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
  }

  public confirmEmail(token: string) {
    let body = new URLSearchParams();
    body.set('token', token);

    return this.http.post(`${environment.API_URL}auth/confirm-email`, body, {
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
  }

  public resetPassword(token: string, newPassword: string) {
    let body = new URLSearchParams();
    body.set('token', token);
    body.set('newPassword', newPassword);

    return this.http.post(`${environment.API_URL}auth/reset-password`, body, {
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
  }

  public redirectUser() {
    const ulrToRedirect = localStorage.getItem('ulrToRedirect');
    if (ulrToRedirect && ( ulrToRedirect.includes('/details') || (!ulrToRedirect.includes('/provider') && !ulrToRedirect.includes('/admin') ))) {
      this.router.navigateByUrl(ulrToRedirect);
      localStorage.removeItem('ulrToRedirect');
      return;
    }
    this.router.navigate([`/`]);
  }
  public redirectAgency() {
    const ulrToRedirect = localStorage.getItem('ulrToRedirect');
    if (ulrToRedirect && (ulrToRedirect.includes('/agency') || ulrToRedirect.includes('/details') )) {
      this.router.navigateByUrl(ulrToRedirect);
      localStorage.removeItem('ulrToRedirect');
      return;
    }
    this.router.navigate([`/agency`]);
  }
  public redirectProvider() {
    const ulrToRedirect = localStorage.getItem('ulrToRedirect');
    if (ulrToRedirect && (ulrToRedirect.includes('/provider') || ulrToRedirect.includes('/details') )) {
      this.router.navigateByUrl(ulrToRedirect);
      localStorage.removeItem('ulrToRedirect');
      return;
    }
    this.router.navigate([`/provider`]);
  }
  public redirectAdmin() {
    const ulrToRedirect = localStorage.getItem('ulrToRedirect');
    if (ulrToRedirect && ( ulrToRedirect.includes('/admin') || ulrToRedirect.includes('/details') )) {
      this.router.navigateByUrl(ulrToRedirect);
      localStorage.removeItem('ulrToRedirect');
      return;
    }
    this.router.navigate([`/admin`]);
  }
}
